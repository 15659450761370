import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Signup from "../views/Signup.vue";
import Reset from "../views/Reset.vue";
import Signin from "../views/Signin.vue";
import Tables from "../views/Tables.vue";
import Users from "../views/Users.vue";
import AddUser from "../views/AddUser.vue";
import Verify from "../views/Verify.vue"
import Wizard from "../views/Wizard.vue"
import NipCheck from "../views/NipCheck.vue"
import CustomerW from "../views/CustomerW.vue"
import Transactions from "../views/Transactions.vue"
import AddWidget from "../views/AddWidget.vue"
import Widgets from "../views/Widgets.vue"


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
                        meta: {
      displayName: "Dashboard",
    },
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
                    meta: {
      displayName: "Dashboard",
    },
  },
    {
    path: "/reset",
    name: "reset",
      component: Reset,
                meta: {
      displayName: "Resetowanie hasła",
    },
  },
      {
    path: "/verify",
    name: "verify",
        component: Verify,
            meta: {
      displayName: "Weryfikacja użytkownika",
    },
    
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
            meta: {
      displayName: "Rejestracja",
    },
    
  },
    {
    path: "/wizard",
    name: "Wizard",
      component: Wizard,
            meta: {
      displayName: "Dodaj fundację",
    },
    
  },
      {
    path: "/customerW",
    name: "CustomerW",
        component: CustomerW,
            meta: {
      displayName: "Dodaj firmę",
    },
    
  },
      {
    path: "/nip",
    name: "NipCheck",
        component: NipCheck,
            meta: {
      displayName: "NIP",
    },
    
  },
    {
    path: "/signin",
      name: "Signin",
            meta: {
      displayName: "Logowanie",
    },
    
    component: Signin,
  },
    {
    path: "/users",
    name: "Users",
    meta: {
      displayName: "Użytkownicy"
    },
    component: Users,
  },
  {
    path: "/transactions",
    name: "Transactions",
    meta: {
      displayName: "Transakcje"
    },
    component: Transactions,
  },
  {
    path: "/adduser",
        name: "AddUser",
        meta: {
      displayName: "Dodaj użytkownika",
    },
    
    component: AddUser,
  },
  {
    path: "/addWidget",
        name: "AddWidget",
        meta: {
      displayName: "Dodaj widget",
    },
    
    component: AddWidget,
  },
  {
    path: "/widgets",
        name: "Widgets",
        meta: {
      displayName: "Widgety",
    },
    
    component: Widgets,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },/*
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },

*/
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
