<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-6 offset-3">
        <div class="card z-index-0" v-if="emailSended">
          <div>
            <div class="card-header m-0 text-center pb-0">
              <svg
                class="mb-2"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  opacity="0.1"
                  cx="20.0004"
                  cy="20.2154"
                  rx="16.3519"
                  ry="16.4295"
                  fill="#27AE60"
                />
                <path
                  d="M13.0508 20.2152L17.6784 24.8648L26.9499 15.5657"
                  stroke="#27AE60"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h5>Dodano</h5>
            </div>
            <!-- emailSended -->
            <div class="text-center pa-4 card-body pt-0">
              <hr />
              <p class="text-sm mb-0">
                Dodany widget czeka na weryfikację administratora
              </p>
              <router-link to="/widgets">
                <argon-button
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="my-4 mb-2"
                  >Powrót do listy</argon-button
                >
              </router-link>
            </div>
          </div>
        </div>

        <div class="card mb-4" v-else>
          <div class="card-header pb-0">
            <h6>Dodaj nowy widget</h6>
          </div>
          <div class="card-body pa-4">
            <form role="form">
              <div>
                <argon-input
                  type="text"
                  placeholder="Tekst wyświetlany*"
                  v-model="item.displayText"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Host (adres WWW)*"
                  v-model="item.key"
                />
              </div>
              <div class="row">

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 1"
                  v-model.number="item.amounts[0].value"
                />
                </div>

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 2"
                  v-model.number="item.amounts[1].value"
                />
                </div>

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 3"
                  v-model.number="item.amounts[2].value"
                />
                </div>

              </div>



              <p>Dane Przelewy24</p>

              <div>
                <argon-input
                  type="text"
                  placeholder="Id sprzedawcy*"
                  v-model="item.credentials.merchantId"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Pos Id*"
                  v-model="item.credentials.posId"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="CRC*"
                  v-model="item.credentials.crc"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Secret Key*"
                  v-model="item.credentials.clientSecret"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Adres powrotny (po dokonaniu płatności)*"
                  v-model="item.credentials.urlReturn"
                />
              </div>

              <div class="text-center">
                <argon-button
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="my-4 mb-2"
                  @click.prevent="addWidget()"
                  >Dodaj widget</argon-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";

export default {
  name: "AddWidget",
  components: {
    ArgonButton,
    ArgonInput,
  },
  data() {
    return {
      emailSended: false,
      user: JSON.parse(sessionStorage.getItem("user")),
      item: { amounts: [
        {value: null},{value: null},{value: null},
      ], credentials: {} },
    };
  },
  methods: {
    pushRole(role) {
      this.item.roles = [];
      this.item.roles.push(role);
    },
    addWidget() {
        this.item.contractorId = this.user.contractorId
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "Contractor/CreateP24Data",
          this.item,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.data = res.data;
          this.emailSended = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
span.label-description {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
</style>