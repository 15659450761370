<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row" v-if="!response">
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>Skonfiguruj swoje konto</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 offset-3">
                <p>
                  Wprowadź NIP swojej organizacji aby pobrać dane z GUS i
                  przyspieszyć wypełnianie formularza. Jeśli Twoja organizacja
                  jest już w bazie, będziesz mógł/-a wysłać do swojej
                  organizacji prośbę o przypisanie Twojego konta.
                </p>
                <argon-input
                  type="text"
                  placeholder="NIP*"
                  v-model="v$.item.taxId.$model"
                  :class="{
                    'is-invalid': v$.item.taxId.$errors.length,
                  }"
                />
                <argon-button
                  style="float: right"
                  color="dark"
                  variant="gradient"
                  class="ma-4 btn-sm"
                  @click.prevent="checkNip()"
                  >Pobierz dane</argon-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 row" v-else-if="response && emailSended">
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>Skonfiguruj swoje konto</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 offset-3 text-center">
                <svg
                  class="mb-2"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    opacity="0.1"
                    cx="20.0004"
                    cy="20.2154"
                    rx="16.3519"
                    ry="16.4295"
                    fill="#27AE60"
                  />
                  <path
                    d="M13.0508 20.2152L17.6784 24.8648L26.9499 15.5657"
                    stroke="#27AE60"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <h5>
                  Świetnie. Twoja prośba została wysłana do
                  {{ response.foundationName }}
                </h5>
                <hr />
                <p>
                  Kiedy Twoje konto zostanie przypisane zostaniesz o tym
                  poinformowany mailowo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row" v-else>
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>Skonfiguruj swoje konto</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 offset-3">
                <p>
                  NIP <strong>{{ response.taxId }}</strong> należy do
                  <strong>{{ response.foundationName }}</strong
                  >, która istnieje w naszej bazie. Wyślij prośbę o przypisanie
                  Twojego konta do tej organizacji, aby uzyskać dostęp do
                  zasobów:
                </p>
                <argon-button
                  full-width
                  color="dark"
                  variant="gradient"
                  class="ma-4"
                  @click.prevent="sendEmailRequest"
                  >Wyślij prośbę o przypisanie konta</argon-button
                >
                <div class="mt-4 text-sm mb-4 text-center">
                  <a href="#" @click="response = null">Powrót</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import ArgonInput from "@/components/ArgonInput.vue";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "nipCheck",
  components: {
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      v$: useValidate(),
      isLoading: true,
      item: {},
      response: null,
      user: JSON.parse(sessionStorage.getItem("user")),
      formItem: {},
      emailSended: false,
    };
  },
  validations() {
    return {
      item: {
        taxId: { required },
      },
    };
  },
  created() {
    //this.getUsers();
  },
  methods: {
    checkNip() {
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Contractor/GetByTaxId/" +
            this.item.taxId,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.response = res.data;
          } else {
            if (this.$store.state.isMerchantPath) {
              this.$router.push({ name: "CustomerW" });
            } else {
              this.$router.push({ name: "Wizard" });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendEmailRequest() {
        this.formItem.id = this.response.id;
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "PaywallUser/SendContractorVerificationRequestEmail",
          this.formItem,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.emailSended = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.vue-form-wizard.md .wizard-icon-circle {
  width: 16px;
  height: 16px;
  font-size: 24px;
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}

.vue-form-wizard.md .disabled .wizard-icon-circle {
  border-color: #dee2e6 !important;
  background: #dee2e6 !important;
}

.vue-form-wizard.md .wizard-icon-circle i {
  display: none !important;
}

.vue-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
  top: 12px;
  height: 2px;
  background: #dee2e6;
}

.vue-form-wizard
  .wizard-navigation
  .wizard-progress-with-circle
  .wizard-progress-bar {
  background-color: #172b4d !important;
}

.wizard-icon-circle.checked .wizard-icon-container {
  background-color: #172b4d !important;
}

.stepTitle {
  color: #dee2e6 !important;
  font-size: 14px;
  font-weight: 600;
}

.stepTitle.active {
  color: #172b4d !important;
}

.wizard-btn {
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}
</style>
