<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-3 pb-11 border-radius-lg"
    >
      <span class="mask bg-gradient-dark opacity-6"> </span>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <navbar isBtn="bg-gradient-light" />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0" v-if="isLoading">
            <div class="card-body">
              <div class="loader">
                <pulse-loader
                  :loading="isLoading"
                  color="#26336A"
                ></pulse-loader>
              </div>
            </div>
          </div>

          <!-- else card -->
          <div class="card z-index-0" v-else>
            <div>
              <div class="card-header m-0 text-center pb-0">
                <div
                  class="alert alert-danger alert-dismissible fade show"
                  role="alert"
                  v-if="responseError"
                >
                  <span class="alert-text"
                    ><strong>Error!</strong>{{ responseErrorText }}</span
                  >
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    @click="responseError = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <svg
                  v-if="responseOK"
                  width="28"
                  height="29"
                  viewBox="0 0 28 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7031 17.875L10.1094 15.2812C9.95312 15.125 9.95312 14.8594 10.1094 14.7031L10.6719 14.1406C10.8281 13.9844 11.0781 13.9844 11.2344 14.1406L13 15.8906L16.75 12.1406C16.9062 11.9844 17.1562 11.9844 17.3125 12.1406L17.875 12.7031C18.0312 12.8594 18.0312 13.125 17.875 13.2812L13.2812 17.875C13.125 18.0312 12.8594 18.0312 12.7031 17.875Z"
                    fill="#2DCE89"
                  />
                  <rect
                    x="0.5"
                    y="1"
                    width="27"
                    height="27"
                    rx="13.5"
                    stroke="#2DCE89"
                  />
                </svg>

                <h3 v-if="responseOK">Twoje konto jest już aktywne!</h3>
              </div>
              <!-- emailSended -->
              <div class="text-center pa-4 card-body pt-0" v-if="responseOK">
                <hr />
                <p class="text-sm mb-0">Teraz możesz się zalogować</p>
                <router-link to="/signin">
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    >Zaloguj się</argon-button
                  >
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "Verify",
  components: {
    Navbar,
    AppFooter,
    ArgonButton,
    PulseLoader,
  },
  data() {
    return {
      isLoading: true,
      item: {},
      emailSended: false,
      resetItem: { email: "" },
      formItem: {
        userId: this.$route.query.id,
        code: this.$route.query.code,
      },
      responseError: false,
      responseErrorText: "",
      responseOK: false,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
    this.verifyAccount();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    verifyAccount() {
      this.isLoading = true;

      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "PaywallUser/VerifyUserEmail",
          this.formItem
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.responseOK = true;
            this.isLoading = false;
          }
          //
        })
        .catch((err) => {
          console.log(err);
          this.responseError = true;
          this.responseErrorText = err.response.data;
          this.isLoading = false;
        });
    },
  },
};
</script>