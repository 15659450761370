<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>Transakcje</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <argon-input
                  type="date"
                  placeholder="Data od"
                  v-model="serverOptions.dateFrom"
                />
              </div>
              <div class="col-4">
                <argon-input
                  type="date"
                  placeholder="Data do"
                  v-model="serverOptions.dateTo"
                />
              </div>
              <div class="col-4">
                <argon-input
                type="number"
                  placeholder="Strona"
                  v-model.number="serverOptions.page"
                />
              </div>
            </div>
            <div class="loader" v-if="isLoading">
                <pulse-loader
                  :loading="isLoading"
                  color="#26336A"
                ></pulse-loader>
              </div>
            <EasyDataTable
              v-if="!isLoading"
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="transactions"
            >
            <template #empty-message>
    Brak danych dla wybranego zakresu...
  </template>
              <template #item-donationAmount="{ donationAmount }">
                <div style="text-align: center">
                  <argon-badge color="success">
                    {{ donationAmount }}
                  </argon-badge>
                </div>
              </template>
              <template #item-paymentTypeId="{ paymentTypeId }">
                {{ paymentTypeName(paymentTypeId) }}
              </template>
              <template #item-merchantType="{ merchantType }">
                {{ paymentTypeName(merchantType) }}
              </template>
              <template #loading>
                <p style="align: text-center; margin: 40px;"></p>
    Trwa ładowanie...
  </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EasyDataTable from "vue3-easy-data-table";
import axios from "axios";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "users",
  components: { EasyDataTable, ArgonBadge, ArgonInput, PulseLoader },
  data() {
    return {
      isLoading: true,
      transactions: null,
      user: JSON.parse(sessionStorage.getItem("user")),
      serverItemsLength: null,
      headers: [
        { text: "Data", value: "transactionDate" },
        { text: "Kwota wsparcia", value: "donationAmount", align: "center" },
        { text: "Broker", value: "brokerName" },
        { text: "Typ płatności", value: "paymentTypeId" },
        { text: "Typ merchanta", value: "merchantType" },
        { text: "MCC", value: "mccCode" },
        { text: "URL Strony", value: "url" },
        { text: "Miasto", value: "city" },
        { text: "Ulica", value: "street" },
      ],
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: "id",
        sortType: "desc",
        dateFrom: "2023-12-14",
        dateTo: "2023-12-21",
      },
      paymentTypes: [],
      merchantTypes: [],
      loading: false,
    };
  },
  created() {
    this.getEnums();
    this.getData();
  },
  watch: {
  serverOptions: {
    handler() {
      this.getData();
    },
    deep: true
  },
},
  methods: {
    getData() {
      this.isLoading = true;
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "DonationReports/Donations?ContractorId=" +
            this.user.contractorId +
            "&Page=" +
            this.serverOptions.page +
            "&PageSize=" +
            this.serverOptions.rowsPerPage +
            "&dateFrom=" + this.serverOptions.dateFrom +
            "&dateTo=" + this.serverOptions.dateTo,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.transactions = res.data.items;
          this.serverOptions.page = res.data.page;
          this.serverOptions.rowsPerPage = res.data.pageSize;
          this.serverItemsLength = res.data.totalItems;
          this.loading = false;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEnums() {
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Common/GetEnum/PaymentType",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.paymentTypes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Common/GetEnum/MerchantType",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.merchantTypes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    paymentTypeName(id) {
      return this.paymentTypes.find((s) => s.id === id).description;
    },
    merchantTypeName(id) {
      if (id) {
        return this.merchantTypes.find((s) => s.id === id).description;
      }
    },
    getLastWeeksDate() {
  const now = new Date();

  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7,
  );
}
  },
};
</script>

<style>
table thead th,
table tbody td {
  padding: 1rem 0.2rem !important;
}

table thead th {
  text-transform: uppercase !important;
  font-size: 10px;
}

table tbody td {
  font-size: 14px;
  border-bottom: 0.8px solid #dee2e6;
}

table thead {
  border-top: 0 !important;
}
</style>
