<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>
              Lista użytkowników
              <router-link to="/adduser">
                <argon-button
                  color="dark"
                  variant="gradient"
                  class="btn-sm ml-5"
                  >+ Dodaj użytkownika</argon-button
                ></router-link
              >
            </h6>
          </div>
          <div class="card-body">
            <EasyDataTable :headers="headers" :items="users" hide-footer="true">
              <template #item-roles="{ roles }" v-if="paywallUserRoles">
                <span class="mr-1" v-for="role in roles" :key="role">{{
                  roleName(role)
                }}</span>
              </template>
              <template #item-status="{ status }" v-if="paywallUserStatuses">
                <argon-badge color="success" v-if="status == 3">
                  {{ statusName(status) }}
                </argon-badge>
                <argon-badge color="info" v-else>
                  {{ statusName(status) }}
                </argon-badge>
              </template>
              <template #item-action="item">
                <argon-button v-if="item.status != 3"
                  color="dark"
                  variant="gradient"
                  class="btn-sm ml-5"
                  >Aktywuj</argon-button
                >
              </template>
            </EasyDataTable>
          </div>
        </div>

        <!--<users-table />-->
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import EasyDataTable from "vue3-easy-data-table";
import axios from "axios";

export default {
  name: "users",
  components: { EasyDataTable, ArgonButton, ArgonBadge },
  data() {
    return {
      isLoading: true,
      users: [],
      user: JSON.parse(sessionStorage.getItem("user")),
      headers: [
        { text: "Id", value: "id", sortable: true },
        { text: "Nazwa użytkownika", value: "username", sortable: true },
        { text: "Rola", value: "roles", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "", value: "action", sortable: false },
      ],
      paywallUserRoles: null,
      paywallUserStatuses: null,
    };
  },
  created() {
    this.getUsers();
    this.getEnums();
  },
  methods: {
    getUsers() {
      axios
        .get(process.env.VUE_APP_BACK_OFFICE_API_URL + "PaywallUser/Get", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEnums() {
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Common/GetEnum/PaywallUserRoleEnum",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.paywallUserRoles = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Common/GetEnum/PaywallUserStatus",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.paywallUserStatuses = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    statusName(id) {
      if (id) {
        return this.paywallUserStatuses.find((s) => s.id === id).description;
      }
    },
    roleName(id) {
      if (id) {
        return this.paywallUserRoles.find((s) => s.id === id).description;
      }
    },
  },
};
</script>
<style>
table thead th,
table tbody td {
  padding: 1rem 0.2rem !important;
}

table thead th {
  text-transform: uppercase !important;
  font-size: 10px;
}

table tbody td {
  font-size: 14px;
  border-bottom: 0.8px solid #dee2e6;
}

table thead {
  border-top: 0 !important;
}
</style>