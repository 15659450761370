<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card md-4">
          <div class="card-header pb-0">
            <h6>
              Widgety
              <router-link to="/addWidget">
                <argon-button
                  color="dark"
                  variant="gradient"
                  class="btn-sm ml-5"
                  >+ Dodaj widget</argon-button
                ></router-link
              >
            </h6>
          </div>
          <div class="card-body">
            <EasyDataTable :headers="headers" :items="data" :hide-footer="true">
              <template #item-status="{ status }" v-if="widgetStatus">
                <argon-badge color="success" v-if="status == 'Verified'">
                  {{ statusName(status) }}
                </argon-badge>
                <argon-badge color="warning" v-else>
                  {{ statusName(status) }}
                </argon-badge>
              </template>
              <template #item-actions="item">
                <argon-button
                color="dark"
                  variant="gradient"
                  class="btn-sm ma-0"
                  @click.prevent="editItem = item"
                  data-bs-toggle="modal" data-bs-target="#editModal"
                  >Edytuj</argon-button
                >
              </template>
            </EasyDataTable>
            <h6>Instrukcja instalacji:</h6>
            <p>Umieść kod w miejscu, w którym widget ma być wyświetlany:</p>

            <code>&lt;div id="widgetp24"&gt;&lt;/div&gt;</code>

            <p>Przed tagiem kończącym &lt;/body&gt; umieść skrypt:</p>

            <code>&lt;script&gt;<br>
const link = document.createElement('link');<br>
link.setAttribute('rel', 'stylesheet');<br>
link.setAttribute('href', 'https://betterpos.pl/widget-dev/style.css');<br>
document.head.appendChild(link)<br>
<br>
const div = document.getElementById("widgetp24")<br>
div.setAttribute('id', 'app')<br>
document.body.appendChild(div)<br>
<br>
const script = document.createElement('script')<br>
script.async = true<br>
script.setAttribute('src', 'https://betterpos.pl/widget-dev/widget.js')<br>
document.body.appendChild(script)<br>
&lt;/script&gt;<br>
            </code>
            
          </div>
        </div>

<!-- Modal -->
<div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Edytuj widget</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form role="form" v-if="editItem" class="ma-4">
              <div class="mt-4">
                <argon-input
                  type="text"
                  placeholder="Tekst wyświetlany*"
                  v-model="editItem.displayText"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Host (adres WWW)*"
                  v-model="editItem.key"
                />
              </div>
              <div class="row">

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 1"
                  v-model.number="editItem.amounts[0].value"
                />
                </div>

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 2"
                  v-model.number="editItem.amounts[1].value"
                />
                </div>

                <div class="col-4">
                    <argon-input
                  type="text"
                  placeholder="Kwota 3"
                  v-model.number="editItem.amounts[2].value"
                />
                </div>

              </div>

              <div class="text-center">
                <argon-button
                fullWidth
                  color="dark"
                  variant="gradient"
                  class=" mb-2"
                  data-bs-dismiss="modal"
                  @click.prevent="saveWigdet()"
                  >Zapisz dane widgetu</argon-button
                >
              </div>



              <p class="mt-4">Dane Przelewy24</p>

              <div>
                <argon-input
                  type="text"
                  placeholder="Id sprzedawcy*"
                  v-model="editItem.credentials.merchantId"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Pos Id*"
                  v-model="editItem.credentials.posId"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="CRC*"
                  v-model="editItem.credentials.crc"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Secret Key*"
                  v-model="editItem.credentials.clientSecret"
                />
              </div>

              <div>
                <argon-input
                  type="text"
                  placeholder="Adres powrotny (po dokonaniu płatności)*"
                  v-model="editItem.credentials.urlReturn"
                />
              </div>

              <div class="text-center">
                <argon-button
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="mb-2"
                  @click.prevent="saveCredential()"
                  data-bs-dismiss="modal"
                  >Zapisz dane p24</argon-button
                >
              </div>
            </form>
      </div>
    </div>
  </div>
</div>
        <!--<users-table />-->
      </div>
    </div>
    
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import EasyDataTable from "vue3-easy-data-table";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";

export default {
  name: "users",
  components: { EasyDataTable, ArgonButton, ArgonBadge, ArgonInput },
  data() {
    return {
      isLoading: true,
      data: [],
      editItem: null,
      user: JSON.parse(sessionStorage.getItem("user")),
      headers: [
        { text: "Id", value: "id", sortable: true },
        { text: "Host", value: "key", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Akcje", value: "actions", sortable: false },

      ],
      widgetStatus: [
      { title: "Verified", description: "Zweryfikowany" },
      { title: "Unverified", description: "Niezweryfikowany" }
      ]
    };
  },
  created() {
    this.getUsers();
  },
  mounted() {
    setTooltip();
  },
  methods: {
    getUsers() {
      axios
        .get(process.env.VUE_APP_BACK_OFFICE_API_URL + "Contractor/GetAllWidgets", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveCredential() {
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "Contractor/UpdateP24Credentials",
          this.editItem.credentials,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.getUsers();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveWigdet() {
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "Contractor/UpdateP24Widget",
          this.editItem,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.getUsers();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    statusName(id) {
      if (id) {
        return this.widgetStatus.find((s) => s.title === id).description;
      }
    },
  },
};
</script>
<style>
table thead th,
table tbody td {
  padding: 1rem 0.2rem !important;
}

table thead th {
  text-transform: uppercase !important;
  font-size: 10px;
}

table tbody td {
  font-size: 14px;
  border-bottom: 0.8px solid #dee2e6;
}

table thead {
  border-top: 0 !important;
}
</style>