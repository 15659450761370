import { createApp } from "vue";
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import App from "./App.vue";
import { store } from './store';
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

const appInstance = createApp(App);
appInstance.use(Vue3FormWizard)
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueCookies)
appInstance.use(VueAxios, axios)
appInstance.mount("#app");
