<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-3 pb-11 border-radius-lg"
    >
      <span class="mask bg-gradient-dark opacity-6"> </span>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <navbar />
          </div>
        </div>
        <h1 class="text-white mb-2 mt-5 text-center">Witaj ponownie!</h1>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center mb-5">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0" v-if="isLoading">
            <div class="card-body">
              <div class="loader">
                <pulse-loader
                  :loading="isLoading"
                  color="#26336A"
                ></pulse-loader>
              </div>
            </div>
          </div>

          <div class="card z-index-0" v-else>
            <div class="card-header pt-4" v-if="!sended">
              <h3>Zaloguj się</h3>
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
                v-if="responseError"
              >
                <span
                  class="alert-text"
                  v-for="el in responseErrorText"
                  :key="el.$uid"
                  >{{ el }}</span
                >
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  @click="responseError = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <form role="form">
                <div class="sm-3">
                  <argon-input
                    type="email"
                    placeholder="Adres e-mail*"
                    v-model="v$.item.email.$model"
                    :v="v$.item.email"
                  />
                </div>

                <div class="sm-2">
                  <argon-input
                    type="password"
                    placeholder="Hasło*"
                    v-model="v$.item.password.$model"
                    :v="v$.item.password"
                  />
                </div>

                <div class="text-center">
                  <argon-button
                    :disabled="v$.item.$invalid"
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    @click.prevent="login()"
                    >Zaloguj</argon-button
                  >
                  <p class="text-sm mt-3 mb-0">
                    Nie masz konta?
                    <router-link to="/signup">
                      <a class="text-dark font-weight-bolder"
                        >Zarejestruj się</a
                      >
                    </router-link>
                  </p>

                  <p class="text-sm mt-3 mb-0">
                    Nie pamiętasz hasła?
                    <router-link to="/reset">
                      <a class="text-dark font-weight-bolder">Odzyskaj hasło</a>
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";

export default {
  name: "Signin",
  components: {
    AppFooter,
    Navbar,
    ArgonInput,
    ArgonButton,
    PulseLoader,
  },
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      item: {},
      responseError: false,
      responseErrorText: "",
    };
  },
  validations() {
    return {
      item: {
        email: { required, email },
        password: { required },
      },
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    login() {
      this.isLoading = true;
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        axios
          .post(
            process.env.VUE_APP_BACK_OFFICE_API_URL + "Auth/SignIn",
            this.item
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("user", JSON.stringify(res.data));
              sessionStorage.setItem("isLogin", true);
              this.$router.push({ name: "Dashboard" });
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            this.responseError = true;
            this.responseErrorText = err.response.data;
          });
      }
    },
  },
};
</script>