<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-6 offset-3">
        <div class="card z-index-0" v-if="emailSended">
          <div>
            <div class="card-header m-0 text-center pb-0">
              <svg
                class="mb-2"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  opacity="0.1"
                  cx="20.0004"
                  cy="20.2154"
                  rx="16.3519"
                  ry="16.4295"
                  fill="#27AE60"
                />
                <path
                  d="M13.0508 20.2152L17.6784 24.8648L26.9499 15.5657"
                  stroke="#27AE60"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h5>Dodano nowego użytkownika</h5>
            </div>
            <!-- emailSended -->
            <div class="text-center pa-4 card-body pt-0">
              <hr />
              <p class="text-sm mb-0">
                Dodany przez Ciebie użytkownik otrzymał właśnie wiadomość e-mail
                z tymczasowym hasłem do panelu.
              </p>
              <router-link to="/users">
                <argon-button
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="my-4 mb-2"
                  >Powrót do listy użytkowników</argon-button
                >
              </router-link>
            </div>
          </div>
        </div>

        <div class="card mb-4" v-else>
          <div class="card-header pb-0">
            <h6>Dodaj nowego użytkownika</h6>
          </div>
          <div class="card-body pa-4">
            <form role="form">
              <div>
                <argon-input
                  type="text"
                  placeholder="Nazwa użytkownika*"
                  v-model="item.username"
                />
              </div>

              <div>
                <argon-input
                  type="email"
                  placeholder="Adres e-mail*"
                  v-model="item.email"
                />
              </div>

              <p style="color: #172b4d">Uprawnienia użytkownika*</p>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="role1"
                  name="legalForm"
                  value="1"
                  v-on:change="pushRole(1)"
                />
                <label class="custom-control-label" for="role1">
                  Administrator
                  <span class="label-description"
                    >Może przeglądać i edytować zawartość oraz dodawać i usuwać
                    użytkowników</span
                  >
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="role2"
                  name="legalForm"
                  value="2"
                  v-on:change="pushRole(2)"
                  checked
                />
                <label class="custom-control-label" for="role2">
                  Przeglądający
                  <span class="label-description"
                    >Może przeglądać zawartość</span
                  >
                </label>
              </div>

              <div class="text-center">
                <argon-button
                  fullWidth
                  color="dark"
                  variant="gradient"
                  class="my-4 mb-2"
                  @click.prevent="addUser()"
                  >Dodaj użytkownika</argon-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";

export default {
  name: "AddUser",
  components: {
    ArgonButton,
    ArgonInput,
  },
  data() {
    return {
      emailSended: false,
      item: { roles: [2] },
    };
  },
  methods: {
    pushRole(role) {
      this.item.roles = [];
      this.item.roles.push(role);
    },
    addUser() {
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "PaywallUser/Create",
          this.item,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.users = res.data;
          this.emailSended = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
span.label-description {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
</style>