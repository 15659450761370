import { createStore } from 'vuex'
import VueCookies from "vue-cookies";

console.log(VueCookies.get("isLogin"))

export const store = createStore({
  state () {
    return {
      showNavs: true,
      showSidenav: true,
      sidebarType: "bg-white",
      showNavbar: true,
      showFooter: true,
      showMain: true,
      isLogin: Boolean(VueCookies.get("isLogin")),
      layout: "default",
      user: VueCookies.get("user"),
      isMerchantPath: false,
    }
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    }
  },
  getters: {}
})