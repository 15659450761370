<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />
      <li class="nav-item d-xl-none ps-3 d-flex align-items-center"
      v-if="user">
        <a
          href="#"
          @click="toggleSidebar"
          class="p-0 nav-link text-white"
          id="iconNavbarSidenav"
        >
          <div class="sidenav-toggler-inner">
            <svg
              width="21"
              height="13"
              viewBox="0 0 21 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.14746 1.55884H20.5897"
                stroke="white"
                stroke-width="2"
              />
              <path d="M0 6.55884H20.5896" stroke="white" />
              <path
                d="M5.14746 11.5588H20.5897"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>
        </a>
      </li>
      <div style="flex: 40"></div>

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center" v-if="isLogin">
            <a
              @click.prevent="logout()"
              class="px-0 nav-link font-weight-bold text-white"
              href="#"
            >
              <i class="fa fa-user me-sm-2"></i>
              <span class="d-sm-inline d-none">Wyloguj</span>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center" v-else>
            <router-link
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold text-white"
            >
              <i class="fa fa-user me-sm-2"></i>
              <span class="d-sm-inline d-none">Zaloguj</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      user: JSON.parse(sessionStorage.getItem("user")),
      isLogin: sessionStorage.getItem("isLogin"),
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout() {
      sessionStorage.clear();
      this.$router.push({ name: "Signin" });
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.meta.displayName;
    },
  },
};
</script>
