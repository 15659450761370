<template>
  <div class="py-4 container-fluid">
    <div class="row" v-if="!user">
      <div class="col-12">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-2">Brak dostępu</h6>
            </div>
          </div>
          <div class="card-body">Zaloguj się...</div>
        </div>
      </div>
    </div>
    <div class="mt-4 row" v-else-if="!user.contractorId">
      <div class="col-12">
        <div class="card md-4 text-center">
          <div class="card-header pb-0">
            <svg
              width="38"
              height="47"
              viewBox="0 0 38 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin: 20px"
            >
              <path
                d="M32.6667 26.1059V18.7806C32.6634 15.5167 31.5051 12.3594 29.3971 9.86762C27.289 7.37588 24.3671 5.71056 21.1489 5.16659V2.65572C21.1489 2.04478 20.9062 1.45887 20.4742 1.02687C20.0422 0.594867 19.4563 0.352173 18.8454 0.352173C18.2344 0.352173 17.6485 0.594867 17.2165 1.02687C16.7845 1.45887 16.5418 2.04478 16.5418 2.65572V5.16659C13.3236 5.71056 10.4018 7.37588 8.29372 9.86762C6.18565 12.3594 5.0274 15.5167 5.02409 18.7806L5.02409 26.1059C3.67986 26.5811 2.51552 27.4605 1.69071 28.6235C0.865905 29.7865 0.420999 31.1761 0.416992 32.6019L0.416992 37.209C0.416992 37.8199 0.659687 38.4058 1.09169 38.8378C1.52369 39.2698 2.1096 39.5125 2.72054 39.5125H9.95369C10.4842 41.4645 11.6422 43.1876 13.2491 44.4162C14.8561 45.6447 16.8226 46.3103 18.8454 46.3103C20.8681 46.3103 22.8347 45.6447 24.4416 44.4162C26.0486 43.1876 27.2066 41.4645 27.7371 39.5125H34.9702C35.5812 39.5125 36.1671 39.2698 36.5991 38.8378C37.0311 38.4058 37.2738 37.8199 37.2738 37.209V32.6019C37.2698 31.1761 36.8249 29.7865 36.0001 28.6235C35.1753 27.4605 34.0109 26.5811 32.6667 26.1059V26.1059ZM9.63119 18.7806C9.63119 16.3368 10.602 13.9931 12.33 12.2651C14.058 10.5371 16.4016 9.56637 18.8454 9.56637C21.2891 9.56637 23.6328 10.5371 25.3608 12.2651C27.0888 13.9931 28.0596 16.3368 28.0596 18.7806V25.6912H9.63119V18.7806ZM18.8454 41.8161C18.0414 41.8112 17.2527 41.596 16.5576 41.1919C15.8625 40.7878 15.2853 40.2088 14.8833 39.5125H22.8075C22.4055 40.2088 21.8283 40.7878 21.1332 41.1919C20.4381 41.596 19.6494 41.8112 18.8454 41.8161ZM32.6667 34.9054H5.02409V32.6019C5.02409 31.9909 5.26679 31.405 5.69879 30.973C6.13078 30.541 6.7167 30.2983 7.32764 30.2983H30.3631C30.9741 30.2983 31.56 30.541 31.992 30.973C32.424 31.405 32.6667 31.9909 32.6667 32.6019V34.9054Z"
                fill="url(#paint0_linear_657_1529)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_657_1529"
                  x1="4.7531"
                  y1="41.8378"
                  x2="-3.07174"
                  y2="25.8759"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F55C3A" />
                  <stop offset="0.955194" stop-color="#F53B58" />
                </linearGradient>
              </defs>
            </svg>

            <h6>Twoje konto wymaga działania</h6>
            <hr style="width: 40%; margin: 20px auto" />
          </div>
          <div class="card-body">
            <p>
              Wybierz typ konta i uzupełnij dane, aby wyświetlać statystyki i
              korzystać z funkcji dopasowanych do Twojej organizacji:
            </p>

            <div class="card-buttons-wrapper">
              <div class="card text-center card-button">
                <div class="card-body">
                  <svg
                    width="59"
                    height="55"
                    viewBox="0 0 59 55"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.2531 4.09672L29.5532 4.32314L29.854 4.09769C33.2804 1.53008 37.5004 0.295718 41.7466 0.617682C45.9929 0.93965 49.9856 2.79676 52.9981 5.85314L52.9982 5.85331C54.6515 7.52912 55.9634 9.51933 56.8586 11.7104C57.7538 13.9015 58.2147 16.2502 58.2147 18.6224C58.2147 20.9945 57.7538 23.3433 56.8586 25.5343C55.9634 27.7254 54.6515 29.7156 52.9982 31.3914L52.9981 31.3915L31.269 53.4311L31.2675 53.4326C31.0425 53.6627 30.7752 53.8449 30.4813 53.9691C30.1873 54.0934 29.8722 54.1572 29.5542 54.1572C29.2362 54.1572 28.9211 54.0934 28.6271 53.9691C28.3331 53.8449 28.0658 53.6627 27.8408 53.4326L27.8394 53.4311L6.11024 31.3915L6.10901 31.3903C2.91622 28.1745 1.04213 23.8497 0.866154 19.2865C0.690173 14.7233 2.22549 10.2629 5.16117 6.80365C8.09668 3.34457 12.2139 1.14356 16.6831 0.641597C21.1522 0.139645 25.6436 1.37367 29.2531 4.09672ZM49.5975 27.9226L50.438 27.0713H50.0482C51.7894 24.705 52.6992 21.8034 52.6129 18.8332C52.5162 15.5067 51.1772 12.3403 48.8647 9.97524C46.5521 7.61002 43.4385 6.22305 40.1537 6.09717C36.8688 5.9713 33.6606 7.11605 31.1783 9.29785L31.1641 9.31032L31.1509 9.32383C30.9259 9.55392 30.6586 9.73616 30.3646 9.86039C30.0706 9.98461 29.7556 10.0485 29.4375 10.0485C29.1195 10.0485 28.8044 9.98461 28.5105 9.86039C28.2165 9.73616 27.9492 9.55392 27.7242 9.32383L27.7234 9.32298C25.3119 6.86862 22.0372 5.48398 18.6179 5.47546L18.6128 5.4755C16.0542 5.49533 13.5589 6.28109 11.44 7.73336C9.32122 9.18558 7.67344 11.2393 6.70231 13.6362C5.7312 16.033 5.47961 18.6668 5.97882 21.2077C6.47804 23.7486 7.70606 26.0844 9.50991 27.9216L9.5109 27.9226L29.1984 47.8618L29.5542 48.2221L29.91 47.8618L49.5975 27.9226Z"
                      fill="#F5365C"
                      stroke="#F5365C"
                    />
                  </svg>
                  <h6>Organizacja dobroczynna</h6>
                  <hr class="mt-0 horizontal dark" />
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="ma-4 btn-sm"
                    @click.prevent="activeFoundation()"
                    >Aktywuj</argon-button
                  >
                </div>
              </div>

              <div class="card text-center card-button">
                <div class="card-body">
                  <svg
                    width="51"
                    height="55"
                    viewBox="0 0 51 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M43.3451 10.3939H35.7201V7.85223C35.7201 5.82996 34.9167 3.89051 33.4867 2.46054C32.0568 1.03058 30.1173 0.227234 28.0951 0.227234H23.0117C20.9894 0.227234 19.05 1.03058 17.62 2.46054C16.1901 3.89051 15.3867 5.82996 15.3867 7.85223V10.3939H7.76172C5.73944 10.3939 3.79999 11.1972 2.37003 12.6272C0.940065 14.0572 0.136719 15.9966 0.136719 18.0189V40.8939C0.136719 42.9162 0.940065 44.8556 2.37003 46.2856C3.79999 47.7156 5.73944 48.5189 7.76172 48.5189H43.3451C45.3673 48.5189 47.3068 47.7156 48.7367 46.2856C50.1667 44.8556 50.9701 42.9162 50.9701 40.8939V18.0189C50.9701 15.9966 50.1667 14.0572 48.7367 12.6272C47.3068 11.1972 45.3673 10.3939 43.3451 10.3939ZM20.4701 7.85223C20.4701 7.17814 20.7378 6.53166 21.2145 6.055C21.6911 5.57835 22.3376 5.31057 23.0117 5.31057H28.0951C28.7691 5.31057 29.4156 5.57835 29.8923 6.055C30.3689 6.53166 30.6367 7.17814 30.6367 7.85223V10.3939H20.4701V7.85223ZM45.8867 40.8939C45.8867 41.568 45.6189 42.2145 45.1423 42.6911C44.6656 43.1678 44.0191 43.4356 43.3451 43.4356H7.76172C7.08763 43.4356 6.44114 43.1678 5.96449 42.6911C5.48783 42.2145 5.22005 41.568 5.22005 40.8939V26.9147C11.6918 29.4822 18.591 30.8016 25.5534 30.8035C32.5156 30.7992 39.4143 29.4798 45.8867 26.9147V40.8939ZM45.8867 21.3485C39.4776 24.1641 32.5537 25.6179 25.5534 25.6179C18.5531 25.6179 11.6291 24.1641 5.22005 21.3485V18.0189C5.22005 17.3448 5.48783 16.6983 5.96449 16.2217C6.44114 15.745 7.08763 15.4772 7.76172 15.4772H43.3451C44.0191 15.4772 44.6656 15.745 45.1423 16.2217C45.6189 16.6983 45.8867 17.3448 45.8867 18.0189V21.3485Z"
                      fill="#164194"
                    />
                  </svg>
                  <h6>Firma</h6>
                  <hr class="mt-0 horizontal dark" />
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="ma-4 btn-sm"
                    @click.prevent="activeCorporate()"
                    >Aktywuj</argon-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 row" v-else-if="!user.foundationVerified">
      <div class="col-12">
        <div class="card md-4 text-center">
          <div class="card-header pb-0">
            <svg
              width="38"
              height="47"
              viewBox="0 0 38 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin: 20px"
            >
              <path
                d="M32.6667 26.1059V18.7806C32.6634 15.5167 31.5051 12.3594 29.3971 9.86762C27.289 7.37588 24.3671 5.71056 21.1489 5.16659V2.65572C21.1489 2.04478 20.9062 1.45887 20.4742 1.02687C20.0422 0.594867 19.4563 0.352173 18.8454 0.352173C18.2344 0.352173 17.6485 0.594867 17.2165 1.02687C16.7845 1.45887 16.5418 2.04478 16.5418 2.65572V5.16659C13.3236 5.71056 10.4018 7.37588 8.29372 9.86762C6.18565 12.3594 5.0274 15.5167 5.02409 18.7806L5.02409 26.1059C3.67986 26.5811 2.51552 27.4605 1.69071 28.6235C0.865905 29.7865 0.420999 31.1761 0.416992 32.6019L0.416992 37.209C0.416992 37.8199 0.659687 38.4058 1.09169 38.8378C1.52369 39.2698 2.1096 39.5125 2.72054 39.5125H9.95369C10.4842 41.4645 11.6422 43.1876 13.2491 44.4162C14.8561 45.6447 16.8226 46.3103 18.8454 46.3103C20.8681 46.3103 22.8347 45.6447 24.4416 44.4162C26.0486 43.1876 27.2066 41.4645 27.7371 39.5125H34.9702C35.5812 39.5125 36.1671 39.2698 36.5991 38.8378C37.0311 38.4058 37.2738 37.8199 37.2738 37.209V32.6019C37.2698 31.1761 36.8249 29.7865 36.0001 28.6235C35.1753 27.4605 34.0109 26.5811 32.6667 26.1059V26.1059ZM9.63119 18.7806C9.63119 16.3368 10.602 13.9931 12.33 12.2651C14.058 10.5371 16.4016 9.56637 18.8454 9.56637C21.2891 9.56637 23.6328 10.5371 25.3608 12.2651C27.0888 13.9931 28.0596 16.3368 28.0596 18.7806V25.6912H9.63119V18.7806ZM18.8454 41.8161C18.0414 41.8112 17.2527 41.596 16.5576 41.1919C15.8625 40.7878 15.2853 40.2088 14.8833 39.5125H22.8075C22.4055 40.2088 21.8283 40.7878 21.1332 41.1919C20.4381 41.596 19.6494 41.8112 18.8454 41.8161ZM32.6667 34.9054H5.02409V32.6019C5.02409 31.9909 5.26679 31.405 5.69879 30.973C6.13078 30.541 6.7167 30.2983 7.32764 30.2983H30.3631C30.9741 30.2983 31.56 30.541 31.992 30.973C32.424 31.405 32.6667 31.9909 32.6667 32.6019V34.9054Z"
                fill="url(#paint0_linear_657_1529)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_657_1529"
                  x1="4.7531"
                  y1="41.8378"
                  x2="-3.07174"
                  y2="25.8759"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F55C3A" />
                  <stop offset="0.955194" stop-color="#F53B58" />
                </linearGradient>
              </defs>
            </svg>

            <h6>Twoje konto nie jest jeszcze aktywne</h6>
            <hr style="width: 40%; margin: 20px auto" />
          </div>
          <div class="card-body">
            <p>
              Twoja fundacja została utworzona i czeka na weryfikację -
              poinformujemy Cię o zaakceptowaniu Twojego wniosku, a jeśli były w
              nim błędy - będziemy się kontaktować.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 row" v-else>
      <div class="col-12">
        <div class="card md-4 text-center">
          <div class="card-header pb-0">
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7031 17.875L10.1094 15.2812C9.95312 15.125 9.95312 14.8594 10.1094 14.7031L10.6719 14.1406C10.8281 13.9844 11.0781 13.9844 11.2344 14.1406L13 15.8906L16.75 12.1406C16.9062 11.9844 17.1562 11.9844 17.3125 12.1406L17.875 12.7031C18.0312 12.8594 18.0312 13.125 17.875 13.2812L13.2812 17.875C13.125 18.0312 12.8594 18.0312 12.7031 17.875Z"
                fill="#2DCE89"
              />
              <rect
                x="0.5"
                y="1"
                width="27"
                height="27"
                rx="13.5"
                stroke="#2DCE89"
              />
            </svg>

            <h6>Twoje konto jest juz aktywne!</h6>
            <hr style="width: 40%; margin: 20px auto" />
          </div>
          <div class="card-body">
            <p>Ale nie wiem co z tym dalej zrobić ;)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>
<script>
//import Card from "@/examples/Cards/Card.vue";
//import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
//import Carousel from "./components/Carousel.vue";
//import CategoriesCard from "./components/CategoriesCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import AppFooter from "@/examples/PageLayout/Footer.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
      isLogin: sessionStorage.getItem("isLogin"),
      stats: {
        money: {
          title: "SPRZEDAŻ",
          value: "$53,000",
          percentage: "+55%",
          iconClass: "ni ni-money-coins",
          detail: "since yesterday",
          iconBackground: "bg-gradient-primary",
        },
        clients: {
          title: "Donacje",
          value: "+3,462",
          percentage: "-2%",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "since last quarter",
        },
        sales: {
          title: "Sales",
          value: "$103,430",
          percentage: "+5%",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
          detail: "than last month",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  created() {
    if (this.isLogin) {
      this.getUser();
    } else {
      this.$router.push({ name: "Signin" });
    }

    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = true;
  },
  components: {
    AppFooter,
    //Card,
    //GradientLineChart,
    //Carousel,
    //CategoriesCard,
    ArgonButton,
  },
  methods: {
    activeCorporate() {
      this.$store.state.isMerchantPath = true;
      this.$router.push({ name: "NipCheck" });
    },
    activeFoundation() {
      this.$router.push({ name: "NipCheck" });
    },
    getUser() {
      this.isLoading = true;
      axios
        .get(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "PaywallUser/GetCurrent",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            sessionStorage.setItem("user", JSON.stringify(res.data));
            sessionStorage.setItem("isLogin", true);
            this.isLogin = true;
            this.user = res.data;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.card-buttons-wrapper {
  display: inline-flex;
}
.card-button {
  width: 245px;
  margin: 20px;
}

.card-button h6 {
  margin: 20px auto;
}
</style>