<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 position-absolute w-100 page-header-landing"
  />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3"
    :class="`
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebarType}`"
    id="sidenav-main"
    v-if="user"
  >
    <div class="" v-if="user">
      <span
        class="font-weight-bold m-0 navbar-brand"
        style="white-space: break-spaces"
        v-if="user.contractorName"
        >{{ user.contractorName }}</span
      >
      <span class="font-weight-bold m-0 navbar-brand" v-else>{{
        user.email
      }}</span>
      <span class="close-sidenav" @click="toggleSidebar()">x</span>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
    };
  },
  props: ["layout"],
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
};
</script>
<style>
.close-sidenav {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>