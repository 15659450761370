<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-3 pb-11 border-radius-lg"
    >
      <span class="mask bg-gradient-dark opacity-6"> </span>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <navbar isBtn="bg-gradient-light" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0" v-if="isLoading">
            <div class="card-body">
              <div class="loader">
                <pulse-loader
                  :loading="isLoading"
                  color="#26336A"
                ></pulse-loader>
              </div>
            </div>
          </div>

          <div class="card z-index-0" v-else>
            <div class="card-header pt-4 pb-1" v-if="!sended">
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
                v-if="responseError"
              >
                <span
                  class="alert-text"
                  v-for="el in responseErrorText"
                  :key="el.$uid"
                  >{{ el }}</span
                >
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  @click="responseError = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h3>Zarejestruj konto</h3>
            </div>
            <div class="card-body">
              <div v-if="sended" class="text-center">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z"
                    fill="#F7A600"
                  />
                </svg>

                <h3>Prawie gotowe</h3>
                <hr />
                <p class="text-sm">
                  Na podany przez Ciebie adres e-mail wysłaliśmy wiadomość z
                  linkiem weryfikacyjnym. Kliknij w niego aby aktywować swoje
                  konto.
                </p>
              </div>
              <form role="form" v-if="!sended">
                <div
                  class="form-group"
                  :class="{ error: v$.item.email.$errors.length }"
                >
                  <div class="sm-3">
                    <argon-input
                      type="text"
                      placeholder="Nazwa użytkownika"
                      v-model="v$.item.username.$model"
                      :v="v$.item.username"
                      :submitted="submitted"
                    />
                  </div>

                  <div class="sm-2">
                    <argon-input
                      type="email"
                      placeholder="Email"
                      v-model="v$.item.email.$model"
                      :v="v$.item.email"
                      :submitted="submitted"
                    />
                  </div>

                  <div class="sm-2">
                    <argon-input
                      type="password"
                      placeholder="Hasło"
                      v-model="v$.item.password.$model"
                      :v="v$.item.password"
                      :submitted="submitted"
                    />
                  </div>

                  <div class="sm-2">
                    <argon-input
                      type="password"
                      placeholder="Powtórz hasło"
                      v-model="v$.item.confirm.$model"
                      :v="v$.item.confirm"
                      :submitted="submitted"
                    />
                  </div>

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="terms"
                      name="terms"
                      v-model="item.accepted"
                    />

                    <label class="form-check-label" for="terms">
                      Akceptuję
                      <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                        >Regulamin</a
                      >
                    </label>
                    <div class="errors" v-if="submitted && !item.accepted">
                      <p>Musisz zaakceptować regulamin aby się zarejestrować</p>
                    </div>
                  </div>

                  <div class="text-center">
                    <argon-button
                      fullWidth
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      @click.prevent="signUp()"
                      >Zarejestruj</argon-button
                    >

                    <p class="text-sm mt-3 mb-0">
                      Masz już konto?
                      <router-link to="/signin">
                        <a class="text-dark font-weight-bolder"
                          >Zaloguj się</a
                        ></router-link
                      >
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import useValidate from "@vuelidate/core";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import {
  required,
  email,
  sameAs,
  minLength,
  helpers,
} from "@vuelidate/validators";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const password = helpers.regex(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/
);

const myMinLength = (min) =>
  helpers.withMessage(`Twoje pole jest za krótkie`, minLength(min));

export default {
  name: "Signin",
  components: {
    AppFooter,
    Navbar,
    ArgonInput,
    ArgonButton,
    PulseLoader,
  },
  data() {
    return {
      v$: useValidate(),
      submitted: false,
      isLoading: false,
      item: { accepted: false },
      sended: false,
      responseError: false,
      responseErrorText: "",
    };
  },
  validations() {
    return {
      item: {
        username: {
          required: helpers.withMessage("To pole jest wymagane", required),
          minLength: myMinLength(2),
        },
        email: {
          required: helpers.withMessage("To pole jest wymagane", required),
          email,
        },
        password: {
          required: helpers.withMessage("To pole jest wymagane", required),
          password: helpers.withMessage(
            "Hasło musi zawierać: 1 cyfrę, 1 mała literę, 1 dużą literę, 1 znak specjalny i min 8 znaków",
            password
          ),
        },
        confirm: {
          required: helpers.withMessage("To pole jest wymagane", required),
          sameAs: helpers.withMessage(
            "Hasła muszą być takie same",
            sameAs(this.item.password)
          ),
        },
      },
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    signUp() {
      this.submitted = true;
      this.v$.item.$touch();

      if (this.v$.item.$invalid || !this.item.accepted) {
        return;
      } else {
        this.isLoading = true;
        axios
          .post(
            process.env.VUE_APP_BACK_OFFICE_API_URL + "PaywallUser/Register",
            this.item
          )
          .then((res) => {
            console.log(res);
            this.sended = true;
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.responseError = true;
            this.responseErrorText = err.response.data;
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
