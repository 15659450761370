<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-3 pb-11 border-radius-lg"
    >
      <span class="mask bg-gradient-dark opacity-6"> </span>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <navbar isBtn="bg-gradient-light" />
          </div>
        </div>
        <h1 class="text-white mb-2 mt-5 text-center">Witaj ponownie!</h1>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div
            class="card z-index-0"
            v-if="this.formItem.userId && this.formItem.passwordHash"
          >
            <div class="card-header m-0">
              <p class="text-sm">
                Wprowadź swoje nowe hasło, a następnie zaloguj się używając go
                podczas logowania:<br />
              </p>
            </div>
            <div class="card-body pt-0">
              <form role="form">
                <div class="sm-3">
                  <argon-input
                    type="password"
                    placeholder="Nowe hasło*"
                    v-model="v$.item.password.$model"
                    :v="v$.item.password"
                    :submitted="submitted"
                  />
                </div>

                <div class="sm-2">
                  <argon-input
                    type="password"
                    placeholder="Powtórz nowe hasło*"
                    v-model="v$.item.confirm.$model"
                    :v="v$.item.confirm"
                    :submitted="submitted"
                  />
                </div>

                <div class="text-center">
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    @click.prevent="resetPassword()"
                    >Zapisz</argon-button
                  >
                </div>
              </form>
            </div>
          </div>

          <!-- else card -->
          <div class="card z-index-0" v-else>
            <div v-if="!emailSended">
              <div class="card-header m-0">
                <p class="text-sm">
                  Aby odzyskać hasło wprowadź adres e-mail powiązany z Twoim
                  kontem. Wyślemy na niego wiadomość z linkiem do odzyskiwania
                  hasła
                </p>
              </div>
              <div class="card-body pt-0">
                <form role="form">
                  <div class="sm-3">
                    <argon-input
                      type="email"
                      placeholder="Adres e-mail*"
                      v-model="resetItem.email"
                    />
                  </div>

                  <div class="text-center">
                    <argon-button
                      fullWidth
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      @click.prevent="sendResetLink()"
                      >Odzyskaj hasło</argon-button
                    >
                  </div>
                </form>
              </div>
            </div>
            <!-- emailSended -->
            <div v-else class="text-center pa-4 card-body">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z"
                  fill="#F7A600"
                />
              </svg>

              <h3>Prawie gotowe</h3>
              <hr />
              <p class="text-sm">
                Na podany przez Ciebie adres e-mail wysłaliśmy dalsze instrukcje
                z linkiem do resetowania hasła.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, sameAs, helpers } from "@vuelidate/validators";

const password = helpers.regex(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/
);

export default {
  name: "Reset",
  components: {
    Navbar,
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      v$: useValidate(),
      submitted: false,
      isLoading: true,
      item: {},
      emailSended: false,
      resetItem: { email: "" },
      formItem: {
        userId: this.$route.query.id,
        passwordHash: this.$route.query.pwd,
        newPassword: null,
      },
    };
  },
  validations() {
    return {
      item: {
        password: {
          required: helpers.withMessage("To pole jest wymagane", required),
          password: helpers.withMessage(
            "Hasło musi zawierać: 1 cyfrę, 1 mała literę, 1 dużą literę, 1 znak specjalny i min 8 znaków",
            password
          ),
        },
        confirm: {
          required: helpers.withMessage("To pole jest wymagane", required),
          sameAs: helpers.withMessage(
            "Hasła muszą być takie same",
            sameAs(this.item.password)
          ),
        },
      },
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    resetPassword() {
      console.log("touch");
      this.v$.item.$touch();

      if (this.v$.item.$invalid) {
        return;
      } else {
        this.formItem.newPassword = this.item.password;

        axios
          .post(
            process.env.VUE_APP_BACK_OFFICE_API_URL + "Auth/ResetPassword",
            this.formItem
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$router.push({ name: "Signin" });
            }
            //
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    sendResetLink() {
      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL +
            "Auth/SendResetPasswordEmail",
          this.resetItem
        )
        .then((res) => {
          console.log(res);
          //this.$router.push({ name: "Signin" });
          if (res.status == 200) {
            this.emailSended = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>