<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card z-index-0" v-if="isLoading">
          <div class="card-body">
            <div class="loader">
              <pulse-loader :loading="isLoading" color="#26336A"></pulse-loader>
            </div>
          </div>
        </div>

        <div class="card md-4" v-else>
          <div class="card-header pb-0">
            <h6>Skonfiguruj swoje konto</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-10 offset-1">
                <form-wizard
                  @on-complete="onComplete"
                  back-button-text="Wróć"
                  next-button-text="Dalej"
                  finish-button-text="Zapisz"
                  ref="wizard"
                  @on-change="handleChange"
                >
                  <tab-content
                    title="Informacje ogólne"
                    :before-change="beforeTabSwitch"
                  >
                    <div class="col-6 offset-3">
                      <argon-input
                        type="text"
                        :v="v$.model.generalInfo.name"
                        v-model="v$.model.generalInfo.name.$model"
                        :submitted="submitted"
                        placeholder="Nazwa organizacji*"
                      />

                      <argon-input
                        type="text"
                        placeholder="NIP*"
                        :v="v$.model.generalInfo.taxId"
                        :submitted="submitted"
                        v-model="v$.model.generalInfo.taxId.$model"
                      />
                      <argon-input
                        type="text"
                        placeholder="REGON*"
                        v-model="v$.model.generalInfo.regon.$model"
                        :v="v$.model.generalInfo.regon"
                        :submitted="submitted"
                      />
                      <argon-input
                        type="text"
                        placeholder="KRS"
                        v-model="model.generalInfo.krs"
                      />
                    </div>
                  </tab-content>
                  <tab-content
                    title="Dane kontaktowe"
                    :before-change="beforeTabSwitch2"
                  >
                    <div class="col-6 offset-3">
                      <argon-input
                        type="text"
                        placeholder="Ulica i numer lokalu*"
                        v-model="v$.model.contactInfo.street.$model"
                        :v="v$.model.contactInfo.street"
                        :submitted="submitted2"
                      />
                      <argon-input
                        type="text"
                        placeholder="Kod pocztowy*"
                        v-model="v$.model.contactInfo.postalCode.$model"
                        :v="v$.model.contactInfo.postalCode"
                        :submitted="submitted2"
                      />
                      <argon-input
                        type="text"
                        placeholder="Miejscowość*"
                        v-model="v$.model.contactInfo.city.$model"
                        :v="v$.model.contactInfo.city"
                        :submitted="submitted2"
                      />
                      <argon-input
                        type="text"
                        placeholder="Region*"
                        v-model="v$.model.contactInfo.region.$model"
                        :v="v$.model.contactInfo.region"
                        :submitted="submitted2"
                      />

                      <argon-input
                        type="email"
                        placeholder="Główny adres e-mail organizacji*"
                        v-model="v$.model.contactInfo.emailAddress.$model"
                        :v="v$.model.contactInfo.emailAddress"
                        :submitted="submitted2"
                      />

                      <argon-input
                        type="text"
                        placeholder="Adres strony internetowej*"
                        v-model="v$.model.contactInfo.webAddress.$model"
                        :v="v$.model.contactInfo.webAddress"
                        :submitted="submitted2"
                      />
                      <argon-input
                        type="text"
                        placeholder="Numer telefonu*"
                        v-model="v$.model.contactInfo.phoneNumber.$model"
                        :v="v$.model.contactInfo.phoneNumber"
                        :submitted="submitted2"
                      />
                    </div>
                  </tab-content>
                  <tab-content title="Dane osobowe">
                    <div class="col-6 offset-3">
                      <p><strong>Osoby do kontaktu: </strong></p>
                      <argon-input
                        type="text"
                        placeholder="Imię i nazwisko*"
                        v-model="
                          v$.model.personalContactInfoList[0].name.$model
                        "
                        :v="v$.model.personalContactInfoList[0].name"
                        :submitted="submitted4"
                      />
                      <argon-input
                        type="text"
                        placeholder="Stanowisko*"
                        v-model="
                          v$.model.personalContactInfoList[0].jobPosition.$model
                        "
                        :v="v$.model.personalContactInfoList[0].jobPosition"
                        :submitted="submitted4"
                      />

                      <argon-input
                        type="email"
                        placeholder="Adres e-mail*"
                        v-model="
                          v$.model.personalContactInfoList[0].emailAddress
                            .$model
                        "
                        :v="v$.model.personalContactInfoList[0].emailAddress"
                        :submitted="submitted4"
                      />
                      <argon-input
                        type="text"
                        placeholder="Numer telefonu*"
                        v-model="
                          v$.model.personalContactInfoList[0].phoneNumber.$model
                        "
                        :v="v$.model.personalContactInfoList[0].phoneNumber"
                        :submitted="submitted4"
                      />
                      <!--
                      <argon-button
                        fullWidth
                        color="dark"
                        variant="gradient"
                        class="my-4 mb-4"
                        @click="addAnother"
                        >Dodaj kolejną osobę</argon-button
                      >-->
                    </div>
                  </tab-content>

                  <template v-slot:footer="props">
                    <div class="wizard-footer-left">
                      <wizard-button
                        v-if="props.activeTabIndex > 0"
                        @click="props.prevTab()"
                        :style="props.fillButtonStyle"
                        >Wróć</wizard-button
                      >
                    </div>
                    <div class="wizard-footer-right">
                      <wizard-button
                        v-if="!props.isLastStep"
                        @click="props.nextTab()"
                        class="wizard-footer-right"
                        :style="props.fillButtonStyle"
                        >Dalej</wizard-button
                      >

                      <wizard-button
                        v-else
                        @click="onComplete"
                        class="wizard-footer-right finish-button"
                        :style="props.fillButtonStyle"
                      >
                        {{
                          props.isLastStep ? "Utwórz firmę" : "Dalej"
                        }}</wizard-button
                      >
                    </div>
                  </template>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="alert fixed-right alert-danger alert-dismissible fade show"
      role="alert"
      v-if="responseError"
    >
      <span class="alert-text"
        ><strong>Error!</strong>{{ responseErrorText }}</span
      >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        @click="responseError = false"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArgonInput from "@/components/ArgonInput.vue";
import useValidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  helpers,
} from "@vuelidate/validators";

const myMaxLength = (max) =>
  helpers.withMessage(`Twoje pole jest za długie`, maxLength(max));

const myMinLength = (min) =>
  helpers.withMessage(`Twoje pole jest za krótkie`, minLength(min));

const regon = helpers.regex(/^(\d{9}|\d{14})$/);

export default {
  name: "wizard",
  components: {
    ArgonInput,
  },
  data() {
    return {
      submitted: false,
      submitted2: false,
      submitted3: false,
      submitted4: false,
      v$: useValidate(),
      responseError: false,
      responseErrorText: "",
      isLoading: false,
      isLoadingF1: false,
      isLoadingF2: false,
      isLoadingF3: false,
      picked: "",
      nipRules: [
        (v) => !!v || "NIP jest wymagany",
        (v) => (v && v.length >= 9) || "NIP musi posiadać minimalnie 9 znaków",
        (v) =>
          (v && v.length <= 14) || "NIP musi posiadać maksymalnie 14 znaków",
      ],
      model: {
        generalInfo: {},
        contactInfo: { countryId: 1 },
        personalContactInfoList: [{}],
      },
      item: {
        generalInfo: {
          legalForm: { legalFormEnum: 1 },
          scopeOfActivities: 1,
        },
        contactInfo: { countryId: 1 },
        documents: {
          bankAccountStatementId: null,
          krsScanId: null,
          statuteScanId: null,
        },
        personalDataList: {
          personsAuthorizedToMakeDeclarationsOfIntents: [{}],
          contactInfoList: [{}],
        },
      },
      LegalFormEnum: [
        {
          id: 1,
          name: "Association",
          description: "Stowarzyszenie",
        },
        {
          id: 2,
          name: "Foundation",
          description: "Fundacja",
        },
        {
          id: 3,
          name: "Organisation",
          description: "Towarzystwo",
        },
        {
          id: 10,
          name: "Other",
          description: "Inne",
        },
      ],
      contractorScopeOfActivitiesEnums: [
        {
          id: 1,
          name: "Local",
          description: "lokalny",
        },
        {
          id: 2,
          name: "Regional",
          description: "wojewódzki",
        },
        {
          id: 3,
          name: "National",
          description: "ogólnopolski",
        },
        {
          id: 10,
          name: "International",
          description: "międzynarodowy",
        },
      ],
    };
  },
  validations() {
    return {
      model: {
        generalInfo: {
          name: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(2),
            maxLength: myMaxLength(128),
          },
          taxId: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(9),
            maxLength: myMaxLength(14),
          },
          regon: {
            required: helpers.withMessage("To pole jest wymagane", required),
            regon: helpers.withMessage(
              "Regon musi zawierać 9 lub 14 znaków",
              regon
            ),
            numeric: helpers.withMessage("Dopuszcalne tylko cyfry", numeric),
          },
        },
        contactInfo: {
          street: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(3),
            maxLength: myMaxLength(255),
          },
          postalCode: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(5),
            maxLength: myMaxLength(12),
          },
          city: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(3),
            maxLength: myMaxLength(255),
          },
          region: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(3),
            maxLength: myMaxLength(255),
          },
          emailAddress: {
            required: helpers.withMessage("To pole jest wymagane", required),
            email,
          },
          webAddress: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(5),
            maxLength: myMaxLength(255),
          },
          phoneNumber: {
            required: helpers.withMessage("To pole jest wymagane", required),
            minLength: myMinLength(9),
            maxLength: myMaxLength(20),
          },
        },

        personalContactInfoList: [
          {
            name: {
              required: helpers.withMessage("To pole jest wymagane", required),
              minLength: myMinLength(3),
              maxLength: myMaxLength(100),
            },
            jobPosition: {
              required: helpers.withMessage("To pole jest wymagane", required),
              minLength: myMinLength(3),
              maxLength: myMaxLength(100),
            },
            phoneNumber: {
              required: helpers.withMessage("To pole jest wymagane", required),
              minLength: myMinLength(9),
              maxLength: myMaxLength(20),
            },
            emailAddress: {
              required: helpers.withMessage("To pole jest wymagane", required),
              email,
            },
          },
        ],
      },
    };
  },
  created() {
    //this.getUsers();
  },
  mounted() {
    this.v$.$touch();
  },
  methods: {
    onFilePicked(event, type) {
      console.log("this.onFilePicked");

      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.uploadFile(type, files[0]);
    },

    addBankAccountStatement() {
      this.isLoadingF1 = true;
      this.$refs.fileInput.click();
    },
    addKrsScan() {
      this.isLoadingF2 = true;
      this.$refs.fileInput2.click();
    },
    addStatuteScan() {
      this.isLoadingF3 = true;
      this.$refs.fileInput3.click();
    },
    onComplete: function () {
      this.isLoading = true;
      this.submitted4 = true;
      this.v$.model.personalContactInfoList.$touch();

      if (this.v$.model.personalContactInfoList.$invalid) {
        return;
      } else {
        axios
          .post(
            process.env.VUE_APP_BACK_OFFICE_API_URL +
              "Contractor/CreateCustomerContractor",

            this.model,
            {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.$router.push({ name: "Dashboard" });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            this.responseError = true;
            this.responseErrorText = err.response.data;
            console.log(err);
          });
      }
    },
    uploadFile(type, file) {
      let formData = new FormData();

      formData.append("Content", file);
      formData.append("DocumentType", type);

      axios
        .post(
          process.env.VUE_APP_BACK_OFFICE_API_URL + "Document/Upload",
          formData,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res);
          console.log(res.data.id);
          if (type == 1) {
            this.item.documents.bankAccountStatementId = res.data.id;
            this.isLoadingF1 = false;
          } else if (type == 2) {
            this.item.documents.krsScanId = res.data.id;
            this.isLoadingF2 = false;
          } else {
            this.item.documents.statuteScanId = res.data.id;
            this.isLoadingF3 = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.responseError = true;
          this.responseErrorText = err.response.data;
          console.log(err);
        });
    },
    status(validation) {
      return {
        "is-invalid": validation.$error,
        dirty: validation.$dirty,
        touch: validation.$touch,
      };
    },
    beforeTabSwitch: function () {
      console.log("beforeTabSwitch");
      this.submitted = true;
      this.v$.model.generalInfo.$touch();
      let flag = false;

      if (this.v$.model.generalInfo.$invalid) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
    beforeTabSwitch2: function () {
      console.log("beforeTabSwitch");
      this.submitted2 = true;
      this.v$.model.contactInfo.$touch();
      let flag = false;

      if (this.v$.model.contactInfo.$invalid) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
  },
};
</script>

<style>
.vue-form-wizard.md .wizard-icon-circle {
  width: 16px;
  height: 16px;
  font-size: 24px;
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}

.vue-form-wizard.md .disabled .wizard-icon-circle {
  border-color: #dee2e6 !important;
  background: #dee2e6 !important;
}

.vue-form-wizard.md .wizard-icon-circle i {
  display: none !important;
}

.vue-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
  top: 12px;
  height: 2px;
  background: #dee2e6;
}

.vue-form-wizard
  .wizard-navigation
  .wizard-progress-with-circle
  .wizard-progress-bar {
  background-color: #172b4d !important;
}

.wizard-icon-circle.checked .wizard-icon-container {
  background-color: #172b4d !important;
}

.stepTitle {
  color: #dee2e6 !important;
  font-size: 14px;
  font-weight: 600;
}

.stepTitle.active {
  color: #172b4d !important;
}

.wizard-btn {
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}

.files p {
  margin-top: 50px;
}
</style>